import { LeaseEntity } from 'entityTypes/Lease/Lease';
import { sortBy } from 'lodash';
import moment from 'moment';
import { createContext, useContext, useMemo, useState } from 'react';
import { monthDiffByMonth } from 'utils/dateHelper';

import { useGetCachedLeases } from '../Leases/LeasesListPage/useGetCachedLeases';
import {
  defaultExpirationCounts,
  ExpirationCounts,
  filterLease,
  getLeaseExpirationCounts,
} from './components/HomeDashboardGraph/helpers/calculateLeaseSummary';
import { isPropertyAndLeaseFiltersPassing } from './components/HomeDashboardGraph/helpers/filters';

interface IHomeDashboardContext {
  leasesData: LeaseEntity[];
  filteredLeases: LeaseEntity[];
  loadingFinished: boolean;
  expirationFilters: string[];
  setExpirationFilters: React.Dispatch<React.SetStateAction<string[]>>;
  leaseExpirationCountsData: ExpirationCounts;
}

const HomeDashboardContext = createContext<IHomeDashboardContext>({
  leasesData: [],
  filteredLeases: [],
  loadingFinished: false,
  expirationFilters: [],
  setExpirationFilters: () => {},
  leaseExpirationCountsData: defaultExpirationCounts,
});

const sortOrderKeys = ['expireDate', 'expireReviewDate', 'property.streetAddress1', 'leaseName'];

export const HomeDashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const { leasesData, loadingFinished } = useGetCachedLeases(); // Service: leases/find-by-active-client
  const [expirationFilters, setExpirationFilters] = useState<string[]>([]);

  const filteredLeases = useMemo(() => {
    if (expirationFilters.length === 0) {
      return sortBy(leasesData.filter(isPropertyAndLeaseFiltersPassing), sortOrderKeys);
    }

    // Apply filters to leasesData
    return sortBy(
      leasesData.filter((lease) => {
        if (!isPropertyAndLeaseFiltersPassing(lease)) return false;

        const leaseExpireDate = lease.expireDate;
        const currentDate = moment.utc().toDate();
        const expireDateMoment = moment.utc(leaseExpireDate).toDate();
        const diffMonths = leaseExpireDate ? monthDiffByMonth(currentDate, expireDateMoment) : null;

        return expirationFilters.some((filter) => filterLease(lease, filter, diffMonths));
      }),
      sortOrderKeys
    );
  }, [leasesData, expirationFilters]);

  const leaseExpirationCountsData: ExpirationCounts = useMemo(
    () => getLeaseExpirationCounts(leasesData, false),
    [leasesData]
  );

  const providerValue = useMemo(
    () => ({
      leasesData,
      filteredLeases,
      loadingFinished,
      expirationFilters,
      setExpirationFilters,
      leaseExpirationCountsData,
    }),
    [
      leasesData,
      filteredLeases,
      loadingFinished,
      expirationFilters,
      setExpirationFilters,
      leaseExpirationCountsData,
    ]
  );

  return (
    <HomeDashboardContext.Provider value={providerValue}>{children}</HomeDashboardContext.Provider>
  );
};

export function useHomeDashboardContext() {
  return useContext(HomeDashboardContext);
}
