import { Typography } from '@mui/material';
import { Preferences } from 'components/pages/Home/types/Preferences';
import { TBasePaths } from 'constants/translations';
import { i18n } from 'next-i18next';
import colors from 'styles/colors';

import { getRemainingMonths } from './dateHelper';
import { formatDateBasedOnPreferences } from './unitConverters';

export const formatExpirationDate = (
  preferences: Preferences,
  expireDate: string | null | undefined,
  isMonthToMonth: boolean | null,
  returnFormat = '',
  includeRemainingMonths?: boolean
) => {
  const monthlyText = i18n?.t(`${TBasePaths.AVANT_COMMON_WORD}.monthly`);
  if (expireDate) {
    const formattedDate = formatDateBasedOnPreferences(preferences, expireDate);
    let suffix = '';
    if (includeRemainingMonths) {
      const diffMonths = getRemainingMonths(expireDate);
      const mosText = i18n?.t(`${TBasePaths.PA_COMMON_WORD}.mos`);
      suffix = diffMonths ? ` (${diffMonths} ${mosText})` : '';
    }
    return (
      <>
        {formattedDate}
        {suffix}
      </>
    );
  } else if (isMonthToMonth) {
    return (
      <Typography sx={{ color: colors.deepRed, fontWeight: 'bold' }} variant="smallParagraph">
        {monthlyText}
      </Typography>
    );
  }

  return <>{returnFormat}</>;
};

export const getPayeeLabel = (leaseTypePosNeg: number | null | undefined) => {
  if (leaseTypePosNeg === -1) {
    return i18n?.t(`${TBasePaths.AVANT_COMMON_WORD}.payor`) ?? '';
  }

  return i18n?.t(`${TBasePaths.AVANT_COMMON_WORD}.payee`) ?? '';
};
