import { CostDataEntity } from 'entityTypes/CostData';

import { LeaseStatusEnum } from 'enums/PrismaEnums';
import { LeaseEntity } from '../../../../../../entityTypes/Lease/Lease';

export const isPropertyAndLeaseFiltersPassing = (lease: LeaseEntity) => {
  return (
    !lease.property?.deletedAt && !lease.deletedAt && lease.leaseStatus !== LeaseStatusEnum.inactive
  );
};
export const isCostDataInYear = (costData: CostDataEntity, year = new Date().getFullYear()) => {
  const costDataYear = costData.periodDate ? new Date(costData.periodDate).getUTCFullYear() : year;

  return year === costDataYear;
};
