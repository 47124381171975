import { Grid } from '@mui/material';
import { GridStyled } from 'components/pages/Home/components/HomeDashboard/HomeDashboard.css';
import {
  HomeDashboardGraphWrapper,
  LeaseExpirationText,
  LeftPanel,
  MetricTitleText,
  RightPanel,
} from 'components/pages/Home/components/HomeDashboardGraph/HomeDashboardGraph.css';
import HomeDashboardGraphFilterDropDown, {
  DASHBOARD_GRAPH_TOGGLES,
  DASHBOARD_METRICS,
} from 'components/pages/Home/components/HomeDashboardGraph/HomeDashboardGraphFilterDropDown';
import LeasesExpirations from 'components/pages/Home/components/HomeDashboardGraphLeasesExpirations/HomeDashboardGraphLeasesExpirations';
import LeasesMetrics from 'components/pages/Home/components/HomeDashboardGraphLeasesMetrics/HomeDashboardGraphLeasesMetrics';
import { HomeDashboardGraphSkeletonLoader } from 'components/pages/Home/components/HomeDashboardGraphSkeletonLoader/HomeDashboardGraphSkeletonLoader';
import TotalHeader from 'components/pages/Home/components/HomeDashboardGraphTotalHeader/HomeDashboardGraphTotalHeader';
import { TBasePaths } from 'constants/translations';
import { StackDirectionEnum } from 'enums/StackDirectionEnum';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { formatAmountBasedOnLocale } from 'utils/unitConverters';

import { userModelState } from '../../../../../recoil/atoms/userAtoms';
import { GraphData } from '../../../../../types/dashboard-metric';
import { useHomeDashboardContext } from '../../HomeDashboardContext';
import { Preferences } from '../../types/Preferences';
import { calculateDashboardMetricsForLease } from './helpers/calculateDashboardMetricsForLease';

interface HomeDashboardGraphProps {
  preferences: Preferences;
}

const HomeDashboardGraph = ({ preferences }: HomeDashboardGraphProps) => {
  const { t } = useTranslation();

  const [metric, setMetric] = useState<string>(DASHBOARD_METRICS[3]); // 3 = Market Metro
  const [typeValue, setTypeValue] = useState<string>(DASHBOARD_GRAPH_TOGGLES[0]);
  const [dashboardMetricsData, setDashboardMetricsData] = useState<GraphData>();

  const user = useRecoilValue(userModelState);

  const {
    filteredLeases: leases,
    leaseExpirationCountsData,
    loadingFinished,
  } = useHomeDashboardContext();

  useEffect(() => {
    if (leases) {
      const data = calculateDashboardMetricsForLease(
        preferences,
        leases,
        leaseExpirationCountsData,
        metric
      );
      setDashboardMetricsData(data);
    }
  }, [metric, leases, preferences, user.selectedUserClient, leaseExpirationCountsData]);

  if (!loadingFinished) return <HomeDashboardGraphSkeletonLoader />;

  const costPerAreaTaxed = formatAmountBasedOnLocale(
    dashboardMetricsData?.costPerAreaTaxed ?? 0,
    2,
    preferences.formatNumber
  );

  const lblNumberOfLeasesText = t(`${TBasePaths.PA_COMMON_WORD}.numLeases`);
  const lbltotalCostText = t(`${TBasePaths.PA_COMMON_WORD}.totalCost`);
  const lbltotalCostCurrencyText = preferences.currency;
  const lblrentableAreaText = t(`${TBasePaths.PA_COMMON_WORD}.rentableArea`);
  const lblrentableAreaUOMText = t(`${TBasePaths.PA_ENUM}.unitMeas.${preferences.uom}`);
  const lblCostAreaText = t(`${TBasePaths.PA_COMMON_WORD}.costArea`);
  const lblMetricsText = t(`${TBasePaths.PA_COMMON_WORD}.metrics`);
  const lblCurrencyText = preferences.currency;

  return (
    <HomeDashboardGraphWrapper variant="outlined" square>
      <GridStyled
        container
        direction={StackDirectionEnum.ROW}
        alignContent="flex-start"
        alignItems="stretch"
      >
        {/* HEADER */}
        <Grid
          item
          sm={12}
          container
          direction={StackDirectionEnum.ROW}
          alignContent="flex-start"
          alignItems="stretch"
        >
          <TotalHeader
            cells={[
              {
                label: `${lblNumberOfLeasesText}`,
                value: dashboardMetricsData?.numberOfLeases?.toString() ?? '0',
              },
              {
                label: `${lbltotalCostText} (${lbltotalCostCurrencyText})`,
                value: `${preferences.currencySymbol}${formatAmountBasedOnLocale(
                  dashboardMetricsData?.totalCostTaxed ?? 0,
                  0,
                  preferences.formatNumber
                )}`,
              },
              {
                label: `${lblrentableAreaText} (${lblrentableAreaUOMText})`,
                value: `${formatAmountBasedOnLocale(
                  dashboardMetricsData?.rentableArea ?? 0,
                  0,
                  preferences.formatNumber
                )}`,
              },
              {
                label: `${lblCostAreaText} (${lblCurrencyText})`,
                value: `${preferences.currencySymbol}${costPerAreaTaxed}`,
              },
            ]}
          />
        </Grid>

        {/* HOME PAGE MIDDLE PANEL */}
        <LeftPanel
          item
          sm={6}
          container
          direction={StackDirectionEnum.COLUMN}
          alignContent="flex-start"
          alignItems="stretch"
        >
          <LeaseExpirationText>
            {t(`${TBasePaths.AVANT_COMMON_WORD}.leaseExpiration`).toUpperCase()}
          </LeaseExpirationText>

          <LeasesExpirations />
        </LeftPanel>

        {/* HOME PAGE RIGHT PANEL */}
        <RightPanel
          item
          sm={6}
          container
          direction={StackDirectionEnum.COLUMN}
          alignContent="flex-start"
          alignItems="stretch"
        >
          {/* @ts-ignore */}
          <MetricTitleText>
            {`${lblMetricsText.toUpperCase()} (${lblCurrencyText.toUpperCase()})`}
          </MetricTitleText>

          <HomeDashboardGraphFilterDropDown
            metric={metric}
            typeValue={typeValue}
            onMetricChange={setMetric}
            onTypeValueChange={setTypeValue}
            preferences={preferences}
          />

          <LeasesMetrics typeValue={typeValue} data={dashboardMetricsData} />
        </RightPanel>
      </GridStyled>
    </HomeDashboardGraphWrapper>
  );
};

export default HomeDashboardGraph;
