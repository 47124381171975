import { SxProps, Table, TableBody, TableContainer, Theme, Typography } from '@mui/material';
import { memo } from 'react';

import BarColumnCell from './BarColumnCell';
import CategoriesColumnCell from './CategoriesColumnCell';
import { StyledTableCell, StyledTableRow, StyledWrapper } from './MUITable.css';

interface MUITableProps {
  data: { COUNT: number }[];
  firstColumnLabels: string[];
  firstColumnCategoryKeys?: string[];
  firstColumnIds?: string[];
  barFillColor?: string;
  labelsFormatter: string[];
  cellverticalpadding?: string;
  suffix?: string | null;
  textWrapCategoriesColumnCell?:
    | 'normal'
    | 'pre'
    | 'nowrap'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces';
  sx?: SxProps<Theme>;
  onClickRow?: (categoryKey?: string) => void;
  isRowSelected?: (key: string) => boolean;
}

const MUITable = ({
  data,
  firstColumnLabels,
  firstColumnCategoryKeys,
  barFillColor,
  labelsFormatter,
  cellverticalpadding = '0.6rem',
  suffix,
  textWrapCategoriesColumnCell,
  sx,
  firstColumnIds,
  onClickRow,
  isRowSelected,
}: MUITableProps) => {
  const totalIndex = data.length;
  const lastColumnIndex = data.length > 0 ? Object.entries(data[0]).length - 1 : 0;

  return (
    <StyledWrapper sx={sx} id="tableContainer-wrapper">
      <TableContainer id="tableContainer">
        <Table size="small" id="table">
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow
                key={`metrics-header-row-${index}`}
                onClick={() => onClickRow?.(firstColumnIds?.[index] ?? '')}
                selected={isRowSelected?.(firstColumnIds?.[index] ?? '')}
              >
                <CategoriesColumnCell
                  index={index}
                  firstColumnLabels={firstColumnLabels}
                  cellverticalpadding={cellverticalpadding}
                  textWrap={textWrapCategoriesColumnCell}
                />
                {Object.entries(item).map((entry, idx) => (
                  <StyledTableCell
                    key={`metrics-table-cell-${entry[1]}`}
                    cellverticalpadding={cellverticalpadding}
                  >
                    {index < totalIndex && idx !== lastColumnIndex && (
                      <Typography variant="tableParagraph">{entry[1]}</Typography>
                    )}

                    {idx === lastColumnIndex && ( // bar chart
                      <BarColumnCell
                        data={data}
                        isLastRow={index === totalIndex}
                        value={entry[1]}
                        category={firstColumnCategoryKeys ? firstColumnCategoryKeys[index] : ''}
                        barFillColor={barFillColor}
                        labelsFormatter={labelsFormatter[idx]}
                        suffix={suffix}
                      />
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledWrapper>
  );
};

export default memo(MUITable);
