export enum ChipColor {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Silent = 'silent',
  Periwinkle = 'periwinkle',
  Orange = 'orange',
  Mint = 'mint',
  Blue = 'blue',
  Default = 'default',
  DefaultDark = 'defaultDark',
}
