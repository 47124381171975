import { Grid, Typography } from '@mui/material';
import {
  DashboardLeaseList,
  HomeLeasesCardContainer,
  HomeLeasesCardList,
  HomeLeasesHeader,
} from 'components/pages/Home/components/HomeDashboardList/HomeDashboardList.css';
import HomeDashboardListCard from 'components/pages/Home/components/HomeDashboardListCard/HomeDashboardListCard';
import { HomeDashboardListSkeletonLoader } from 'components/pages/Home/components/HomeDashboardListSkeletonLoader/HomeDashboardListSkeletonLoader';
import { TBasePaths } from 'constants/translations';
import { LeaseEntity } from 'entityTypes/Lease/Lease';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Virtuoso } from 'react-virtuoso';
import colors from 'styles/colors';
import { useHomeDashboardContext } from '../../HomeDashboardContext';
import { Preferences } from '../../types/Preferences';

interface HomeDashboardListProps {
  onSelect?: (lease: LeaseEntity) => void;
  preferences: Preferences;
}

const HomeDashboardList = ({ onSelect, preferences }: HomeDashboardListProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { filteredLeases, loadingFinished } = useHomeDashboardContext();

  const onClickCard = (lease: LeaseEntity) => {
    if (onSelect) {
      onSelect(lease);
    } else {
      router.push(`/leases/${lease.leaseUuid}`);
    }
  };

  return (
    <DashboardLeaseList variant="outlined" square>
      <HomeLeasesCardContainer container spacing={0}>
        <HomeLeasesHeader container>
          <Grid item>
            {/* @ts-ignore */}
            <Typography variant="styledVacantBox" color={colors.midnightLight}>
              {t(`${TBasePaths.PA_COMMON_WORD}.leases`).toUpperCase()}
            </Typography>
          </Grid>
          <Grid item></Grid>
        </HomeLeasesHeader>
        <HomeLeasesCardList container spacing={0}>
          {loadingFinished ? (
            <Virtuoso
              overscan={{ main: 400, reverse: 0 }}
              data={filteredLeases}
              itemContent={(_, lease) => (
                <HomeDashboardListCard
                  key={lease.leaseUuid}
                  lease={lease}
                  preferences={preferences}
                  onClick={() => onClickCard(lease)}
                />
              )}
            />
          ) : (
            <HomeDashboardListSkeletonLoader rows={4} />
          )}
        </HomeLeasesCardList>
      </HomeLeasesCardContainer>
    </DashboardLeaseList>
  );
};

export default HomeDashboardList;
