import { Chip, chipClasses, styled } from '@mui/material';
import { ChipColor } from 'enums/ChipColorEnum';
import colors from 'styles/colors';
import { withTransientMuiProps } from 'styles/common';

interface StyledChipProps {
  $chipSize?: number;
  $minWidth?: number;
  $maxWidth?: number;
  $chipColor?: ChipColor;
  $borderRadius?: string;
}

const chipColorMap: Record<ChipColor, { main: string; bg: string }> = {
  [ChipColor.Success]: {
    main: colors.royalBlueMain,
    bg: colors.royalBlueExtraLight,
  },
  [ChipColor.Error]: {
    main: colors.deepRed,
    bg: colors.errorExtraLight,
  },
  [ChipColor.Warning]: {
    main: colors.warningExtraDark,
    bg: colors.warningExtraLight,
  },
  [ChipColor.Silent]: {
    main: colors.mauveDark,
    bg: colors.mauveExtraLight,
  },
  [ChipColor.Periwinkle]: {
    main: colors.periwinkleDark,
    bg: colors.periwinkleExtraLight,
  },
  [ChipColor.Orange]: {
    main: colors.orangeDark,
    bg: colors.orangeExtraLight,
  },
  [ChipColor.Mint]: {
    main: colors.vibrantGreen,
    bg: colors.lightGreen,
  },
  [ChipColor.Blue]: {
    main: colors.royalBlueMain,
    bg: colors.royalBlueExtraLight,
  },
  [ChipColor.Default]: {
    main: colors.disabledIcon,
    bg: colors.lightGreyBg,
  },
  [ChipColor.DefaultDark]: {
    main: colors.smokyGrey,
    bg: colors.eggShell,
  },
};

export const StyledChip = styled(
  Chip,
  withTransientMuiProps
)<StyledChipProps>(({
  theme,
  $chipSize = 2.4,
  $minWidth,
  $chipColor = ChipColor.Default,
  $maxWidth,
}) => {
  const chipColors = chipColorMap[$chipColor];
  return {
    ...($minWidth ? { minWidth: `${$minWidth}rem` } : {}),
    ...($maxWidth ? { maxWidth: `${$maxWidth}rem` } : {}),
    height: `${$chipSize}rem`,
    borderRadius: '2rem',
    borderWidth: '0.1rem',
    padding: '0 0.2rem',
    [`.${chipClasses.label}`]: {
      fontSize: theme?.typography.styledChipLabel.fontSize,
      lineHeight: theme?.typography.styledChipLabel.lineHeight,
      fontWeight: theme?.typography.styledChipLabel.fontWeight,
      paddingLeft: '0.9rem',
      textTransform: 'capitalize',
    },
    borderColor: chipColors.main,
    color: chipColors.main,
    backgroundColor: chipColors.bg,
    [`.${chipClasses.icon}`]: {
      color: chipColors.main,
    },
  };
});
