import { Preferences } from 'components/pages/Home/types/Preferences';
import { LeaseFormType } from 'components/pages/Leases/LeaseForm/form/LeaseForm';
import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import { TBasePaths } from 'constants/translations';
import { LeaseEntity } from 'entityTypes/Lease/Lease';
import { LeaseSpaceEntity } from 'entityTypes/Lease/LeaseSpace';
import { LeaseSpaceStatusEnum } from 'enums/PrismaEnums';
import { t } from 'i18next';
import moment from 'moment';
import { SetterOrUpdater } from 'recoil';
import { UpdateLeaseSpaceDto } from 'types/lease-space';
import logger from 'utils/logger';

import { isEqual, pickBy } from 'lodash';
import { notEmpty } from './filters';
import { convertCurrency } from './unitConverters';

/**
 * Handles errors gracefully and logs them before returning a fallback value.
 * @param error The caught error or unknown type
 * @param fallbackValue The value to return in case of error
 * @param initiator The value that represents the function name to track
 * @returns The fallback value provided
 */
const handleCatchAndLog = <T>(error: unknown, fallbackValue: T, initiator: string): T => {
  const err = error as Error;
  logger(err, initiator);
  Snackbar.warning(err.message);
  return fallbackValue;
};

export const convertAreaIfNeeded = (
  rentArea: number,
  rentAreaUom: string,
  desiredUom: string
): number => {
  if (rentAreaUom !== desiredUom) {
    return rentAreaUom === 'sqm' ? rentArea * 10.76391042 : rentArea / 10.76391042;
  }
  return rentArea;
};

export const filterActiveLeaseSpaces = (leaseSpaces: LeaseSpaceEntity[]): LeaseSpaceEntity[] => {
  return leaseSpaces.filter((ls) => ls.leaseSpaceStatus === LeaseSpaceStatusEnum.active);
};

export const calculateTotalRentAreaByLease = (lease: LeaseEntity, uom: string): number => {
  try {
    let total = 0;
    const currentDate = moment.utc();
    filterActiveLeaseSpaces(lease.leaseSpace).forEach((ls) => {
      const rentArea = Number(ls.rentArea);
      const commenceDate = ls.commenceDate ? moment.utc(ls.commenceDate) : null;
      const expireDate = ls.expireDate ? moment.utc(ls.expireDate) : null;
      if (
        rentArea &&
        (!commenceDate || commenceDate.isSameOrBefore(currentDate)) &&
        (!expireDate || ls.isMonthToMonth || expireDate.isSameOrAfter(currentDate))
      ) {
        const rentAreaConverted = convertAreaIfNeeded(rentArea, lease.unitMeas, uom);
        total += rentAreaConverted;
      }
    });

    return total;
  } catch (e) {
    return handleCatchAndLog(e, 0, 'calculateTotalRentAreaByLease');
  }
};

export const calculateTotalUsableAreaByLease = (leaseSpaces: LeaseSpaceEntity[]): number => {
  try {
    const currentDate = moment.utc();
    return filterActiveLeaseSpaces(leaseSpaces).reduce((accumulator: number, ls) => {
      const commenceDate = ls.commenceDate ? moment.utc(ls.commenceDate) : null;
      const expireDate = ls.expireDate ? moment.utc(ls.expireDate) : null;

      if (
        (!commenceDate || commenceDate.isSameOrBefore(currentDate)) &&
        (!expireDate || ls.isMonthToMonth || expireDate.isSameOrAfter(currentDate))
      ) {
        return accumulator + Number(ls.usableArea);
      }

      return accumulator;
    }, 0);
  } catch (e) {
    return handleCatchAndLog(e, 0, 'calculateTotalUsableAreaByLease');
  }
};

export const calculateTotalUnitsByLease = (leaseSpaces: LeaseSpaceEntity[]): number => {
  try {
    const currentDate = moment.utc();
    return filterActiveLeaseSpaces(leaseSpaces).reduce((accumulator: number, ls) => {
      const commenceDate = ls.commenceDate ? moment.utc(ls.commenceDate) : null;
      const expireDate = ls.expireDate ? moment.utc(ls.expireDate) : null;

      if (
        (!commenceDate || commenceDate.isSameOrBefore(currentDate)) &&
        (!expireDate || ls.isMonthToMonth || expireDate.isSameOrAfter(currentDate))
      ) {
        return accumulator + Number(ls.unitCnt);
      }

      return accumulator;
    }, 0);
  } catch (e) {
    return handleCatchAndLog(e, 0, 'calculateTotalUnitsByLease');
  }
};

export const calculateAnnualCostPerAreaWithoutTax = (
  lease: LeaseEntity,
  preferences: Preferences
): number | undefined => {
  if (!lease.costSummary) return;

  const totalRentArea = calculateTotalRentAreaByLease(lease, preferences.uom);
  return totalRentArea && totalRentArea != 0
    ? convertCurrency(
        preferences,
        lease.costSummary?.annualCostWithoutTax / totalRentArea,
        lease.currencyCode
      )
    : undefined;
};

export const concatTotalFloorSuitSpaces = (leaseSpaces: LeaseSpaceEntity[]): string => {
  try {
    const filtered = filterActiveLeaseSpaces(leaseSpaces)
      .map((ls) => ls.floorSuiteSpace)
      .filter(notEmpty);
    filtered.sort((a, b) => a.localeCompare(b));
    return filtered.join('; ');
  } catch (e) {
    return handleCatchAndLog(e, '0', 'concatTotalFloorSuitSpaces');
  }
};

export const hasMultipleLeaseSpaces = (leaseSpaces: LeaseSpaceEntity[]): boolean => {
  try {
    return leaseSpaces.length > 1;
  } catch (e) {
    return handleCatchAndLog(e, false, 'hasMultipleLeaseSpaces');
  }
};

export const findRelatedLeaseSpace = (
  leaseSpaces: LeaseSpaceEntity[],
  lease: LeaseEntity | null
): LeaseSpaceEntity | undefined | null => {
  try {
    return (leaseSpaces.find((s) => s.leaseSpaceUuid === lease?.leaseUuid) ?? leaseSpaces.length)
      ? leaseSpaces[0]
      : null;
  } catch (e) {
    return handleCatchAndLog(e, undefined, 'findRelatedLeaseSpace');
  }
};

export const updateLeaseSpaceIfNeeded = async (
  updatedValues: Partial<LeaseFormType>,
  leaseSpaces: LeaseSpaceEntity[],
  lease: LeaseEntity | null,
  onUpdateLeaseSpace: (dto: UpdateLeaseSpaceDto) => Promise<LeaseSpaceEntity>,
  onSetLeaseSpacesState: SetterOrUpdater<LeaseSpaceEntity[]>
) => {
  const relatedLeaseSpace = findRelatedLeaseSpace(leaseSpaces, lease);
  const leaseSpaceUuid = relatedLeaseSpace?.leaseSpaceUuid;
  if (!leaseSpaceUuid) return Snackbar.warning(t(`${TBasePaths.PA_COMMON_WORD}.uuidNotProvided`));

  const changedData = pickBy(
    updatedValues,
    (value: unknown, key: keyof LeaseSpaceEntity) => !isEqual(value, relatedLeaseSpace[key])
  ) as Partial<LeaseSpaceEntity>;

  if (Object.keys(changedData).length) {
    changedData.leaseSpaceUuid = leaseSpaceUuid;
    await onUpdateLeaseSpace(changedData);

    onSetLeaseSpacesState((prev) =>
      prev.map((p) => (p.leaseSpaceUuid === leaseSpaceUuid ? { ...p, ...changedData } : p))
    );
  }
};

export const listOfLeaseSpaceFields: (keyof LeaseSpaceEntity)[] = [
  'rentArea',
  'usableArea',
  'unitCnt',
  'floorSuiteSpace',
  'leaseSpaceStatus',
  'leaseSpaceNote',
  'leaseAreaTypeUuid',
  'isMonthToMonth',
  'planExitDate',
  'commenceDate',
  'expireDate',
  'expireReviewDate',
  'possessionDate',
  'executionDate',
  'openingDate',
  'closingDate',
  'excludeBudget',
];
