import { API_PATH } from 'components/routing/constants/api';
import { Filters } from 'constants/filters';
import { CurrencyEntity } from 'entityTypes/Currency';
import { LeaseEntity } from 'entityTypes/Lease/Lease';
import { LeaseAreaTypeEntity } from 'entityTypes/Lease/LeaseAreaType';
import { LeaseBusinessesEntity } from 'entityTypes/Lease/LeaseBusiness';
import { LeaseSpaceEntity } from 'entityTypes/Lease/LeaseSpace';
import { LeaseTypeEntity } from 'entityTypes/Lease/LeaseType';
import { PropertyEntity } from 'entityTypes/Property/Property';
import { HttpMethod } from 'enums/httpMethodEnum';
import { IDeletedResponse } from 'interfaces';
import { selector, selectorFamily } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import {
  leasesByPropertyState,
  leasesState,
  leasesWithSpaceFinancialsResetState,
  leasesWithSpaceFinancialsState,
} from 'recoil/atoms/leasesAtom';
import {
  GetLeasesWithSpaceFinancialsParams,
  LeaseWithFinancialsResponse,
  LeaseWithSpaceRes,
} from 'types/lease';
import { CreateLeaseSpaceDto } from 'types/lease-space';

import { request } from '../../../pages/api/fetchService';
import { getURLByEndpoint } from './SelectorsHelper';

export const getLeasesSelector = selectorFamily<LeaseEntity[] | undefined, { clientUuid: string }>({
  key: 'GetLeasesWithAllRelationsSelector',
  get:
    ({ clientUuid }) =>
    async (): Promise<LeaseEntity[] | undefined> => {
      if (!clientUuid) return;
      const result = await request<LeaseEntity[]>(
        getURLByEndpoint(API_PATH.lease.findAll),
        HttpMethod.GET
      );
      setRecoil(leasesState(clientUuid), result);
      return result;
    },
});

export const getLeasesWithSpaceFinancialsSelector = selectorFamily<
  LeaseWithFinancialsResponse | undefined,
  GetLeasesWithSpaceFinancialsParams
>({
  key: 'GetLeasesWithSpaceFinancialsSelector',
  get:
    (params) =>
    async ({ get }): Promise<LeaseWithFinancialsResponse | undefined> => {
      get(leasesWithSpaceFinancialsResetState);
      const {
        clientUuid,
        startYear,
        endYear,
        yearQtrMonthView,
        costCategoryUuid,
        costSchedStatus,
        isFY,
        excludeEmptyRecords,
      } = params;
      if (!clientUuid) return;

      let queryString = `?byLeaseSpace=true&frequency=${yearQtrMonthView}&startYear=${startYear}&endYear=${endYear}&isFY=${isFY}&excludeEmptyRecords=${excludeEmptyRecords}`;
      if (costCategoryUuid && costCategoryUuid !== Filters.all) {
        queryString += `&costSchedCategoryUuid=${costCategoryUuid}`;
      }

      if (costSchedStatus && costSchedStatus !== Filters.all) {
        queryString += `&costSchedStatus=${costSchedStatus}`;
      }

      const result = await request<{ leases: LeaseEntity[]; timeArray: string[] }>(
        getURLByEndpoint(API_PATH.lease.findAllWithFinancials, queryString),
        HttpMethod.GET
      );
      setRecoil(leasesWithSpaceFinancialsState(params), result);
      return result;
    },
});

export const getLeaseByIdSelector = selectorFamily<LeaseEntity | undefined, string>({
  key: 'GetLeaseByIdSelector',
  get: (leaseUuid?: string) => async () => {
    if (!leaseUuid) return;
    return request<LeaseEntity>(
      getURLByEndpoint(API_PATH.lease.findOne, `?leaseUuid=${leaseUuid}`),
      HttpMethod.GET
    );
  },
});

export const getLeasesByPropertyIdExcludeSpaces = selectorFamily<LeaseEntity[], string>({
  key: 'GetLeasesByPropertyIdExcludeSpaces',
  get: (propertyUuid: string) => async (): Promise<LeaseEntity[]> => {
    if (!propertyUuid) return [];

    return request<LeaseEntity[]>(
      getURLByEndpoint(
        API_PATH.lease.findAllByPropertyIdExcludeSpaces,
        `?propertyUuid=${propertyUuid}`
      ),
      HttpMethod.GET
    );
  },
});

export const setLeasesByPropertyIdExcludeSpaces = selectorFamily({
  key: 'SetLeasesByPropertyIdExcludeSpaces',
  get:
    (propertyUuid: string) =>
    async ({ get }) => {
      const leasesByPropertyIdExcludeSpaces = get(getLeasesByPropertyIdExcludeSpaces(propertyUuid));

      setRecoil(leasesByPropertyState(propertyUuid), leasesByPropertyIdExcludeSpaces);

      return true;
    },
});

export const getLeaseTypesSelector = selector({
  key: 'GetLeaseTypesSelector',
  get: (): Promise<LeaseTypeEntity[]> => {
    return request(getURLByEndpoint(API_PATH.leaseType.list), HttpMethod.GET);
  },
});

export const getLeaseAreaTypesSelector = selector({
  key: 'GetLeaseAreaTypesSelector',
  get: (): Promise<LeaseAreaTypeEntity[] | undefined> => {
    return request(getURLByEndpoint(API_PATH.leaseAreaType.list), HttpMethod.GET);
  },
});

export const getLeaseBusinessesSelector = selectorFamily({
  key: 'GetLeaseBusinessesSelector',
  get: (clientUuid: string) => async (): Promise<LeaseBusinessesEntity[] | undefined> => {
    if (!clientUuid) return;
    return request(getURLByEndpoint(API_PATH.leaseBusinesses.findByActiveClient), HttpMethod.GET);
  },
});

export const getLeasesSpacesSelector = selectorFamily({
  key: 'leases.getLeasesSpaces',
  get: (parentLeaseUuid: string) => async (): Promise<LeaseSpaceEntity[] | undefined> => {
    if (!parentLeaseUuid) return;
    return request(
      getURLByEndpoint(API_PATH.lease.findSpacesByLease, `?parentLeaseUuid=${parentLeaseUuid}`),
      HttpMethod.GET
    );
  },
});

export const getClientCurrenciesSelector = selectorFamily({
  key: 'GetClientCurrenciesSelector',
  get: () => (): Promise<CurrencyEntity[] | undefined> => {
    return request(getURLByEndpoint(API_PATH.clientCurrencies.findByClient), HttpMethod.GET);
  },
});

export const createLeaseWithSpaceSelector = selector({
  key: 'createLeaseWithSpaceSelector',
  get: ({ getCallback }) => {
    return getCallback(
      () =>
        (data: {
          lease: Partial<LeaseEntity>;
          space: Record<
            keyof Pick<
              CreateLeaseSpaceDto,
              | 'floorSuiteSpace'
              | 'usableArea'
              | 'rentArea'
              | 'unitCnt'
              | 'planExitDate'
              | 'leaseSpaceStatus'
              | 'leaseSpaceNote'
              | 'leaseAreaTypeUuid'
              | 'commenceDate'
              | 'expireDate'
              | 'expireReviewDate'
              | 'executionDate'
              | 'possessionDate'
              | 'openingDate'
              | 'closingDate'
              | 'isMonthToMonth'
              | 'excludeBudget'
            >,
            number | string | boolean | null
          >;
        }) => {
          return request<LeaseWithSpaceRes>(
            getURLByEndpoint(API_PATH.lease.create),
            HttpMethod.POST,
            data
          );
        }
    );
  },
});

export const updateLeaseSelector = selector({
  key: 'UpdateLeaseSelector',
  get: ({ getCallback }) => {
    return getCallback(() => async (data) => {
      return request<LeaseEntity>(getURLByEndpoint(API_PATH.lease.update), HttpMethod.POST, data);
    });
  },
});

export const deleteLeaseSelector = selector({
  key: 'DeleteLeaseSelector',
  get: ({ getCallback }) => {
    return getCallback(() => (leaseUuid: string) => {
      return request<IDeletedResponse<null>>(
        getURLByEndpoint(API_PATH.lease.remove, `?leaseUuid=${leaseUuid}`),
        HttpMethod.DELETE
      );
    });
  },
});

export const getPropertiesAndLeasesByText = selector({
  key: 'GetPropertiesAndLeasesByText',
  get: ({ getCallback }) => {
    return getCallback(() => (text: string | number) => {
      return request<PropertyEntity[] | LeaseEntity[]>(
        getURLByEndpoint(API_PATH.lease.findByText, `?searchText=${text}`),
        HttpMethod.GET
      );
    });
  },
});

export const findLeasesWithMissingPaymentsQuery = selectorFamily({
  key: 'findLeasesWithMissingPaymentsQuery',
  get:
    ({ period, clientUuid }: { period?: string; clientUuid?: string }) =>
    async ({ get }) => {
      get(leasesWithSpaceFinancialsResetState);
      if (!period || !clientUuid) return undefined;

      const res = await request<LeaseEntity[]>(
        getURLByEndpoint(API_PATH.lease.findLeasesWithMissingPayments, `?period=${period}`),
        HttpMethod.GET
      );

      return res;
    },
});
