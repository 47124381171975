import { LeaseEntity } from 'entityTypes/Lease/Lease';
import moment from 'moment';
import { monthDiffByMonth } from 'utils/dateHelper';
import { calculateTotalRentAreaByLease } from 'utils/leaseSpaceHelper';
import { convertCurrency } from 'utils/unitConverters';

import logger from '../../../../../../utils/logger';
import { Preferences } from '../../../types/Preferences';
import { isPropertyAndLeaseFiltersPassing } from './filters';

type Summary = {
  [category: string]: {
    totalRentArea: number;
    totalCostTaxed: number;
  };
};

export const calculateLeaseSummary = (
  leases: LeaseEntity[],
  metric: string,
  preferences: Preferences
) => {
  const summary: Summary = {};

  function getNestedValue(keys: string[], obj: object) {
    for (const key of keys) {
      if (!obj || !Object.hasOwn(obj, key)) {
        return undefined;
      }
      obj = obj[key as keyof typeof obj];
    }
    return obj;
  }

  for (const lease of leases) {
    if (!isPropertyAndLeaseFiltersPassing(lease)) {
      continue;
    }

    // Extract based on lease.property.marketMetro.marketMetroName;
    const categoryName = (getNestedValue(metric.split('.'), lease) ?? 'Not specified') as string;

    if (!summary[categoryName]) {
      summary[categoryName] = {
        totalRentArea: 0,
        totalCostTaxed: 0,
      };
    }

    if (lease.leaseSpace.length) {
      const totalRentAreaByLease = calculateTotalRentAreaByLease(lease, preferences.uom);
      summary[categoryName].totalRentArea += totalRentAreaByLease;
    }

    summary[categoryName].totalCostTaxed += convertCurrency(
      preferences,
      lease.costSummary?.annualCostWithoutTax ?? 0,
      lease.currencyCode
    );
  }

  return {
    summary,
  };
};

export type ExpirationCounts = {
  expired: number;
  monthly: number;
  zeroMonths: number;
  oneYearExpiration: number;
  twoYearsExpiration: number;
  threeYearsExpiration: number;
  fourAndFiveYearsExpiration: number;
  overSixYearsExpiration: number;
  noExpiration: number;
  ownedLeases: number;
};

export const defaultExpirationCounts: ExpirationCounts = {
  expired: 0,
  monthly: 0,
  zeroMonths: 0,
  oneYearExpiration: 0,
  twoYearsExpiration: 0,
  threeYearsExpiration: 0,
  fourAndFiveYearsExpiration: 0,
  overSixYearsExpiration: 0,
  noExpiration: 0,
  ownedLeases: 0,
};

export const expirationPeriods = [
  { startMonth: -Infinity, endMonth: -1, label: 'expired' },
  { startMonth: 1, endMonth: 12, label: 'oneYearExpiration' },
  { startMonth: 13, endMonth: 24, label: 'twoYearsExpiration' },
  { startMonth: 25, endMonth: 36, label: 'threeYearsExpiration' },
  { startMonth: 37, endMonth: 60, label: 'fourAndFiveYearsExpiration' },
  { startMonth: 61, endMonth: Infinity, label: 'overSixYearsExpiration' },
];

export const isWithinExpirationPeriod = (diffMonths: number | null, index: number): boolean => {
  if (diffMonths === null) return false;
  const period = expirationPeriods[index];
  return diffMonths >= period.startMonth && diffMonths <= period.endMonth;
};

export const filterLease = (
  lease: LeaseEntity,
  filter: string,
  diffMonths: number | null
): boolean => {
  switch (filter) {
    case 'ownedLeases':
      return lease.leaseType?.leaseTypeName === 'Owned';
    case 'monthly':
      return lease.leaseSpace.every((space) => space.isMonthToMonth);
    case 'zeroMonths':
      return diffMonths === 0;
    case 'expired':
      return isWithinExpirationPeriod(diffMonths, 0);
    case 'oneYearExpiration':
      return isWithinExpirationPeriod(diffMonths, 1);
    case 'twoYearsExpiration':
      return isWithinExpirationPeriod(diffMonths, 2);
    case 'threeYearsExpiration':
      return isWithinExpirationPeriod(diffMonths, 3);
    case 'fourAndFiveYearsExpiration':
      return isWithinExpirationPeriod(diffMonths, 4);
    case 'overSixYearsExpiration':
      return isWithinExpirationPeriod(diffMonths, 5);
    case 'noExpiration':
      return !lease.expireDate && !lease.isMonthToMonth;
    default:
      return false;
  }
};

export const getLeaseExpirationCounts = (leases: LeaseEntity[], isDebug: boolean) => {
  const currentDate = moment.utc().toDate();

  const expirationCounts = { ...defaultExpirationCounts };

  leases.forEach((lease) => {
    if (!isPropertyAndLeaseFiltersPassing(lease)) return;

    const leaseExpireDate = lease.expireDate;
    const expireDateMoment = moment.utc(leaseExpireDate).toDate();
    const diffMonths = leaseExpireDate ? monthDiffByMonth(currentDate, expireDateMoment) : null;

    switch (true) {
      case filterLease(lease, 'ownedLeases', diffMonths):
        expirationCounts.ownedLeases += 1;
        break;
      case filterLease(lease, 'monthly', diffMonths):
        expirationCounts.monthly += 1;
        break;
      case filterLease(lease, 'zeroMonths', diffMonths):
        expirationCounts.zeroMonths += 1;
        break;
      case filterLease(lease, 'expired', diffMonths):
        expirationCounts.expired += 1;
        break;
      case filterLease(lease, 'oneYearExpiration', diffMonths):
        expirationCounts.oneYearExpiration += 1;
        break;
      case filterLease(lease, 'twoYearsExpiration', diffMonths):
        expirationCounts.twoYearsExpiration += 1;
        break;
      case filterLease(lease, 'threeYearsExpiration', diffMonths):
        expirationCounts.threeYearsExpiration += 1;
        break;
      case filterLease(lease, 'fourAndFiveYearsExpiration', diffMonths):
        expirationCounts.fourAndFiveYearsExpiration += 1;
        break;
      case filterLease(lease, 'overSixYearsExpiration', diffMonths):
        expirationCounts.overSixYearsExpiration += 1;
        break;
      case filterLease(lease, 'noExpiration', diffMonths):
        expirationCounts.noExpiration += 1;
        break;
      default:
        break;
    }
  });

  if (isDebug) {
    logger('===================== Bar chart : calculateLeaseSummary =======================');
    logger(JSON.stringify(expirationCounts));
  }

  return expirationCounts;
};
